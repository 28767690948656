import { createApp } from 'vue';
import Filter from '../components/filter/Filter.vue'

import store from '../vuex'
import { Tab } from 'bootstrap';

export const ProfessionalUserShowView = {

  init() {
    const filter = document.getElementById('filter-wrapper')
    const searchParams = JSON.parse(filter.dataset.searchParams)
    let ssrInit = true
    let personalFilter = undefined
    let utilityFilter = undefined
    let modelType = undefined
    const buildBaseUrl = (slug) => { return `/${slug}` }

    // Needed to propagate sort parameter when switching tabs.
    const getSortFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.get('sort') || ''
    }

    document.addEventListener('show.bs.tab', function(e) {
      const sort = getSortFromUrl()
      if(e.target.id === 'nav-personal-tab') {
        modelType = 'personal'
        if(personalFilter) {
          personalFilter.initialize({sort})
        } else {
          personalFilter = createApp(Filter, { redirect: false, baseUrl: buildBaseUrl(searchParams.slug), modelType: modelType, userSlug: searchParams.slug, searchParams, saveLastSearchUrl: true })
            .use(store)
            .mount('#filter-wrapper-personal')
          if(ssrInit) {
            personalFilter.loadFilterOptions()
          } else {
            personalFilter.initialize({sort})
          }
        }
        window.filter = personalFilter
      } else if(e.target.id === 'nav-utility-tab') {
        modelType = 'utility'
        if(utilityFilter) {
          utilityFilter.initialize({sort})
        } else {
          utilityFilter = createApp(Filter, { redirect: false, baseUrl: buildBaseUrl(searchParams.slug), modelType: modelType, userSlug: searchParams.slug, searchParams, saveLastSearchUrl: true })
            .use(store)
            .mount('#filter-wrapper-utility')
          if(ssrInit) {
            utilityFilter.loadFilterOptions()
          } else {
            utilityFilter.initialize({sort})
          }
        }
        window.filter = utilityFilter
      }
    })

    const tab = new Tab(document.querySelector('#nav-personal-tab'))
    tab.show()
    ssrInit = false
  }

}

export const ProfessionalUserIndexView = {
  init() {
    // Autosubmit pro select region
    const regionSelect = document.querySelector('[data-controller="autosubmit"]');
    if (regionSelect) {
      regionSelect.addEventListener('change', function() {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        
        // Aktualizovat nebo přidat parametr region
        if (this.value === 'Celá ČR') {
          params.delete('region');
        } else {
          params.set('region', this.value);
        }
        
        // Zachovat stránkování na první straně při změně filtru
        params.delete('page');
        
        // Přesměrovat na novou URL s parametrem regionu
        window.location.href = `${url.pathname}?${params.toString()}`;
      });
    }
  }
};