import { Tab } from 'bootstrap';
import { createApp } from 'vue'
import CalculatorForm from "~/src/components/premium/CalculatorForm.vue";
import ImageUpload from "~/src/components/ImageUpload.vue";

export const UserProfileShowView = {

  init() {
    const settingsTab = new Tab(document.querySelector('#nav-settings-tab'))
    const personalTab = new Tab(document.querySelector('#nav-offers-tab'))
    const premiumTab = new Tab(document.querySelector('#nav-premium-tab'))
    if ((location.hash !== null && location.hash === '#nav-settings-tab')) {
      settingsTab.show()
    } else if ((location.hash !== null && location.hash === '#nav-premium-tab')) {
      premiumTab.show()
    } else {
      personalTab.show()
    }

    document.querySelector('.btn-alias-submit').addEventListener('click', function (e ) {
      e.preventDefault();
      let button = e.target;
      button.disabled = true;
      const form = document.querySelector(button.dataset.form);
      if(form) {
        form.requestSubmit();
        const submitButton = form.querySelector("button[type='submit']");
        submitButton.scrollIntoView({ behavior: "smooth" });
        setTimeout(() => {
          button.disabled = false;
        }, 2000)
      } else {
        console.error('Form not found');
      }
    });

    // Inits Premium calculator.
    createApp(CalculatorForm, { action: 'create' })
      .mount('#form-calculator-premium')

    // Inicializace uploaderů
    const uploaders = {
      'logo-upload': {
        url: '/api/v1/user_profiles/upload_logo',
        dimensions: {
          recommended: '600x600px',
          min: '300x300px'
        }
      },
      'banner300x300-upload': {
        url: '/api/v1/user_profiles/upload_banner_small',
        dimensions: {
          recommended: '600x600px',
          min: '300x300px'
        }
      },
      'banner300x600-upload': {
        url: '/api/v1/user_profiles/upload_banner_large',
        dimensions: {
          recommended: '600x1200px',
          min: '300x600px'
        }
      }
    };

    Object.entries(uploaders).forEach(([id, config]) => {
      const mountPoint = document.querySelector(`#${id}-container`);
      if (mountPoint) {
        createApp(ImageUpload, {
          inputId: id,
          uploadUrl: config.url,
          defaultImage: mountPoint.dataset.defaultImage,
          dimensions: config.dimensions,
          onUploadSuccess: (data) => {
            console.log(`Upload úspěšný pro ${id}:`, data);
          },
          onUploadError: (error) => {
            console.error(`Upload selhal pro ${id}:`, error);
          }
        }).mount(mountPoint);
      }
    });
  },
}
