<template>
  <div class="upload-section">
    <div class="text-center">
      <img :src="currentImage" :alt="alt" class="img-fluid mb-3">
      <p class="text-gray-700 small">
        Doporučený rozměr: {{ dimensions.recommended }}, min.: {{ dimensions.min }}<br />
        Formát: PNG, JPG
      </p>
      
      <div class="upload-section mt-2">
        <label :for="uniqueId" class="btn text-primary d-flex align-items-center justify-content-center gap-1">
          <i class="icon icon--lg icon-upload bg-primary"></i>
          <span>{{ buttonText }}</span>
        </label>
        <input 
          type="file" 
          :id="uniqueId" 
          class="d-none" 
          :accept="accept"
          @change="handleFileChange"
          :multiple="multiple"
        >
      </div>
    </div>

    <p v-if="helperText" class="text-gray-700 small mt-2">{{ helperText }}</p>

    <div v-if="error" class="alert alert--error mt-2" role="alert">
      <i class="icon icon-dismiss-circle"></i>
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageUpload',

  props: {
    inputId: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      default: 'Vybrat z počítače'
    },
    alt: {
      type: String,
      default: 'Náhled obrázku'
    },
    accept: {
      type: String,
      default: 'image/png,image/jpeg'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    helperText: {
      type: String,
      default: ''
    },
    uploadUrl: {
      type: String,
      required: true
    },
    defaultImage: {
      type: String,
      required: true
    },
    dimensions: {
      type: Object,
      default: () => ({
        recommended: '600x600px',
        min: '300x300px'
      })
    }
  },

  data() {
    return {
      uploadedImage: null,
      error: null,
      isUploading: false
    }
  },

  computed: {
    uniqueId() {
      return `file-input-${this.inputId}`
    },
    currentImage() {
      return this.uploadedImage || this.defaultImage
    }
  },

  methods: {
    async handleFileChange(event) {
      const files = event.target.files;
      if (!files.length) return;
      
      const file = files[0];
      if (file.size > 5 * 1024 * 1024) {
        this.error = 'Soubor je příliš velký. Maximální velikost je 5 MB.';
        return;
      }

      this.error = null;
      await this.uploadFile(file);
    },

    async uploadFile(file) {
      try {
        this.isUploading = true;
        const formData = new FormData();
        formData.append('file', file);
        
        const response = await fetch(this.uploadUrl, {
          method: 'POST',
          body: formData,
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
          }
        });

        if (!response.ok) {
          throw new Error('Upload selhal');
        }

        const data = await response.json();
        this.uploadedImage = data.url;
        this.$emit('upload-success', data);
      } catch (error) {
        this.error = 'Došlo k chybě při nahrávání souboru. Zkuste to prosím znovu.';
        this.$emit('upload-error', error);
      } finally {
        this.isUploading = false;
      }
    }
  }
}
</script>

<style scoped>
.upload-section label {
  cursor: pointer;
  user-select: none;
}

.upload-section label:hover {
  opacity: 0.9;
}
</style>