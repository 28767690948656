function initRemoteForm(formId, resetForm = true) {
  const form = document.getElementById(formId);
  const submitHandler = (event) => handleRemoteFormSubmit(event, form, resetForm);
  form.removeEventListener("submit",  submitHandler);
  form.addEventListener("submit",  submitHandler);
}

function initForm(formId) {
  const form = document.getElementById(formId);
  const submitHandler = (event) => handleFormSubmit(event, form);
  form.removeEventListener("submit",  submitHandler);
  form.addEventListener("submit",  submitHandler);
}

function handleRemoteFormSubmit(event, form, resetForm = true) {
  event.preventDefault();
  const button = form.querySelector(".btn-submit");
  const successAlert = form.querySelector(".alert--success");
  const dangerAlert = form.querySelector(".alert--danger");
  button.disabled = true;
  // Get the endpoint URL from the form's action attribute
  const endpointUrl = form.action;
  // AJAX request using the form's action URL
  fetch(endpointUrl, {
    method: 'POST',
    body: new FormData(form),
  })
    .then(response => response.json())
    .then(data => {
      if (data.result === "success") {
        showAlert(successAlert, data.msg || "Formulář úspěšně odeslán!", 'success');
        if (resetForm) {
          form.reset();
        }
      } else {
        showAlert(dangerAlert, data.msg || "Nastala chyba při odesílání formuláře.", 'danger');
      }
    })
    .catch(() => {
      showAlert(dangerAlert, "Nastala chyba při odesílání formuláře.", 'danger');
    })
    .finally(() => {
      setTimeout(() => {
        button.disabled = false;
      }, 2000);
    });
}

function handleFormSubmit(event, form) {
  event.preventDefault();
  const button = form.querySelector(".btn-submit");
  event.preventDefault();
  button.disabled = true;
  form.submit();
}

function showAlert(element, message) {
  element.innerHTML = message;
  element.classList.remove("d-none");
  setTimeout(() => {
    element.classList.add("d-none");
  }, 5000);
}


document.addEventListener("DOMContentLoaded", function(event) {
  if (document.getElementById("user-password-reset-form")) {
    initRemoteForm('user-password-reset-form');
  }
  if (document.getElementById("offer-message-form")) {
    initRemoteForm('offer-message-form');
  }
  if (document.getElementById("report-message-form")) {
    initRemoteForm('report-message-form');
  }
  if (document.getElementById("kooperativa-message-form")) {
    initRemoteForm('kooperativa-message-form');
  }
  if (document.getElementById("question-message-form")) {
    initRemoteForm('question-message-form');
  }
  if (document.getElementById("user-signin-form")) {
    initForm('user-signin-form');
  }
  if (document.getElementById("user-profile-form")) {
    initRemoteForm('user-profile-form', false);
  }
  if (document.getElementById("sticker-request-form")) {
    initRemoteForm('sticker-request-form');
  }
});
