import { Popover } from 'bootstrap';

document.addEventListener('DOMContentLoaded', () => {
  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  
  const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    if (popoverTriggerEl.classList.contains('js-popover')) {
      return new Popover(popoverTriggerEl, {
        html: true,
        sanitize: false,
        trigger: 'hover',
      });
    }
    return new Popover(popoverTriggerEl);
  });
  
  document.addEventListener('click', function (event) {
    popoverTriggerList.forEach(function (popoverTriggerEl) {
      const popover = Popover.getInstance(popoverTriggerEl);
      if (popover && !popoverTriggerEl.contains(event.target) && !document.querySelector('.popover')?.contains(event.target)) {
        popover.hide();
      }
    });
  });
}); 